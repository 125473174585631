import React, { Component } from 'react';
import Slider from "react-slick";
import './logo-carousel.css';

class LogoCarousel extends Component {
    renderSlides() {
        const logos = [
            'android',
            'apple',
            'aws',
            'css',
            'firebase',
            'html',
            'js',
            'laravel',
            'mysql',
            'php',
            'react'
        ]

        const slides = [];

        for (let i = 0; i < logos.length; i+=1) {
            slides.push(
                <div className="slide" key={'image-' + logos[i]}>
                    <img src={`assets/images/frameworks/${logos[i]}.png`} alt={logos[i]}></img>
                </div>
            )
        }
        
        return slides;
    }

    render() {
        const settings = {
            arrows: false,
            dots: false,
            infinite: true,
            speed: 500,
            variableWidth: true,
            autoplay: true,
            accessibility: false,
            draggable: false,
            swipe: false,
            centerMode: true,
        };

        return (
            <div id="logo-carousel">
                <Slider {...settings}>
                    {this.renderSlides()}
                </Slider>
           </div>
        );
      }
}

export default LogoCarousel;
