import React, { Component } from "react";
import BgVideo from "./components/bg-video/bg-video.js";
import LogoCarousel from "./components/logo-carousel/logo-carousel.js";

import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="app">
        <BgVideo />

        <header>
          <img
            id="logo"
            src={"assets/images/logo.png"}
            alt="Tapp Agency Logo"
          ></img>
          <h1>Website (design) // Mobile Apps // Consulting</h1>
        </header>

        <section id="logo-carousel">
          <LogoCarousel />
        </section>

        <footer>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@tapp-agency.be">info@tapp-agency.be</a>
          </p>
          <p>
            <strong>Tel:</strong>{" "}
            <a href="tel:032476018897">+32 (0)4 7601 88 97</a>
          </p>
          <p>
            <strong>VAT:</strong> BE 0724 626 325
          </p>
          <p id="emm-link">
            <a
              href="https://gameskeys.net/top-games-to-play-in-september-2020/#Emoji"
              target="_blank"
            >
              Emoji Music Match featured
            </a>
          </p>
        </footer>
      </div>
    );
  }
}

export default App;
