import React, { Component } from 'react';
import BackgroundVideo from 'react-background-video-player';
import './bg-video.css';

class BgVideo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            containerWidth: 0,
            containerHeight: 0
        }

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", this.updateDimensions);
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);

        if ('onorientationchange' in window) {
            window.removeEventListener("orientationchange", this.updateDimensions);
        }
    }

    updateDimensions() {
        this.setState({
            containerWidth: window.innerWidth, 
            containerHeight: window.innerHeight
        });
    }

    render() {
        const {
            containerWidth, 
            containerHeight
        } = this.state;

        return (
            <div id="bg-video">
                <BackgroundVideo 
                    containerWidth={containerWidth}
                    containerHeight={containerHeight}
                    volume={0}
                    src={'./assets/video/bg-video.mp4'}
                    poster={'./assets/images/bg-video-poster.png'}
                />
                <div className="bg-video__overlay"></div>
            </div>
        );
    }
}

export default BgVideo;
